import React, { useEffect, useState } from "react";
import { Header } from "components/Header";
import { Container } from "components/common";
import {
  Wrapper,
  IntroWrapper,
  H2,
  Content,
  Subtitle,
} from "../IntroRandomAnim/styles";
import { Link } from "gatsby";

export const DankePageAnim = ({ h2, subtitle, bgs }) => {
  const [images, setImage] = useState(1);

  const len = bgs.length;
  useEffect(() => {
    let number = Math.floor(Math.random() * len);
    setImage(number);
  }, []);

  return (
    <Wrapper background={bgs[images]}>
      <Header />

      <IntroWrapper as={Container} style={{ textAlign: "center" }}>
        <Content>
          <H2 dangerouslySetInnerHTML={{ __html: h2 }} />
          <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
          <Link
            style={{
              color: "black",
              fontSize: "2.2em",
              textAlign: "center",
              fontWeight: 700,
              border: "3px solid black",
              paddingBlock: "15px",
              paddingInline: "20px",
              marginTop: 20,
              borderRadius: 10,
              background: "white",
            }}
            to="/"
          >
            Zurück zur Startseite
          </Link>
        </Content>
      </IntroWrapper>
    </Wrapper>
  );
};
