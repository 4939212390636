import React from "react";
import { Layout, Seo } from "components/common";
import { dankeContent } from "pagesData/danke";
import { DankePageAnim } from "components/DankePageImg";

const DankePage = () => (
  <Layout>
    <Seo />
    <DankePageAnim   {...dankeContent} />

  </Layout>
);

export default DankePage;
