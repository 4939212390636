
import bg1 from "assets/bilder/uber/_AD_9120_rgb_final.jpg"

export const dankeContent = {
    h2: "Danke für Ihre Nachricht",
    subtitle: "Wir melden uns bei Ihnen",
    bgs: [
      bg1
    ],
    mainContent: `
    `,
}